import { ProductSortKey, SearchRegion, SupplierSortKey } from '../types/catalog';
import { SubCategoryName, VerticalName } from '../types/category';
import {
  SourcingHomeTab,
  SourcingMarketplaceBannerValueProp,
  SupplierProfileInstructionalVideo,
  ViewType,
} from '../types/sourcing-marketplace';
import { STATIC_FILE_URL } from '@/shared/constants';

export const SLACK_COMMUNITY_LINK = 'https://pietra.xyz/community';

export const BEGINNER_WORKSHOP_LINK =
  'https://pietrastudio.zoom.us/webinar/register/WN_tgsKefnpQsKpVM6yMVZ6hg#/registration';

export const BUSINESS_BUILDER_LINK = 'https://builder.pietrastudio.com';

export const AFFILIATE_LINK = 'https://pietra.xyz/affiliate';

export const getSupplierInstructionalVideos = (isServices: boolean): SupplierProfileInstructionalVideo[] => {
  if (isServices) {
    return [
      {
        vimeoId: 866597379,
        thumbnailImage: `${STATIC_FILE_URL}/768e056f884c5fe9bee5214fc39a35d0.png`,
        title: 'An agency or freelancer?',
        timeLength: '11m',
      },
      {
        vimeoId: 866598085,
        thumbnailImage: `${STATIC_FILE_URL}/0cc5cf7f6ecbccfe5cf7c19a1d95d855.png`,
        title: 'Find & hire your first freelancer',
        timeLength: '3m',
      },
    ];
  } else {
    return [
      {
        vimeoId: 852821829,
        thumbnailImage: `${STATIC_FILE_URL}/29c48a55f18922c3decbedf24a6e8c7c.png`,
        title: 'How to source on Pietra',
        timeLength: '1m',
      },
      {
        vimeoId: 866597651,
        thumbnailImage: `${STATIC_FILE_URL}/c9e2a0557e4c68aea127175a8a447a7d.png`,
        title: 'Private label basics',
        timeLength: '6m',
      },
    ];
  }
};

export const SAMPLE_QUANTITY_SELECTOR_ID = 'sample-quantity-selector';
export const PDP_DESCRIPTION_SECTION_ID = 'pdp-description-section-id';
export const PDP_SUPPLIER_CAPABILITIES_SECTION_ID = 'pdp-supplier-capabilities-section-id';
export const PDP_SIMILAR_PRODUCTS_SECTION_ID = 'pdp-similar-products-section-id';
export const PDP_REVIEWS_SECTION_ID = 'pdp-reviews-section-id';

export const PDP_VARIANT_UUID_SEPARATOR = '^&^';

// All Brand Logos
const ADIDAS = `${STATIC_FILE_URL}/49b4bdfc2865094e82292500eb9507fd.png`;
const AMAZON = `${STATIC_FILE_URL}/80e1dac6b488614dfba598b9c14b70c7.png`;
const BADGLEY_MISCHKA = `${STATIC_FILE_URL}/c45e7d52c438ef18f30d159d535b6908.png`;
const BBB = `${STATIC_FILE_URL}/8891254d1b79186079d18a76fe248fd5.png`;
const BELLAGIO = `${STATIC_FILE_URL}/3676a67084a789aa4fab7959227a9cf3.png`;
const BOOTS = `${STATIC_FILE_URL}/a28066fa1a1624efa0233d12e0c0e1d3.png`;
const BUMBLE = `${STATIC_FILE_URL}/a6d843edaa3920d0654dabe4bf12fd42.png`;
const BVLGARI = `${STATIC_FILE_URL}/0a82222077c694a280caadbb78857141.png`;
const CADILLAC = `${STATIC_FILE_URL}/30edd3d5ecf873bacf5e0cda843118e8.png`;
const CHAMBERLAIN_COFFEE = `${STATIC_FILE_URL}/01b15b124ef2a1a1ae676ef2208faea1.png`;
const CONDE_NAST = `${STATIC_FILE_URL}/251bef259a7b698609faab8cc4a8da39.png`;
const COTTON_ON = `${STATIC_FILE_URL}/3ee1108b53cad06e63ad37b126cfdedb.png`;
const DIFF = `${STATIC_FILE_URL}/ec32183d89fb7137d5b62fbec3004af3.png`;
const DISNEY = `${STATIC_FILE_URL}/ba968c8ac32d86d19a7e349c1ed0c9d2.png`;
const ELLA_MOSS = `${STATIC_FILE_URL}/3a75f9249771bb1346154c90b5ffc68c.png`;
const EVERLANE = `${STATIC_FILE_URL}/bae5877df2f6c965ca50b2f1711847bd.png`;
const FACEBOOK = `${STATIC_FILE_URL}/ec0a0fa4c69dad61869c2e03626911d9.png`;
const FERRAGAMO = `${STATIC_FILE_URL}/e9a948f7bf5150ca51958f1891b12593.png`;
const FILA = `${STATIC_FILE_URL}/ee8e6036e27ee9691f29c4da2ca56cbd.png`;
const FOREVER_21 = `${STATIC_FILE_URL}/8099c0a9030f00621ff5fef01b225470.png`;
const FREE_PEOPLE = `${STATIC_FILE_URL}/8f18a79e9969ab56b1fb35fdcc94af7c.png`;
const GUCCI = `${STATIC_FILE_URL}/76b0e85f174cd4ba990aa85216d73037.png`;
const GUESS = `${STATIC_FILE_URL}/c556cd7a30bd506db3c75000394ccf92.png`;
const HANNAFORD = `${STATIC_FILE_URL}/106c12dcfafb9416ea40f1497682df68.png`;
const HOME_GOODS = `${STATIC_FILE_URL}/c1d113049d34be5e43ce2faa63f7704e.png`;
const HUAWEI = `${STATIC_FILE_URL}/17e74c7e6d7bb73748204ebe39c9876c.png`;
const KAPPA = `${STATIC_FILE_URL}/cffc88240cf14668e920a92c9c04dad9.png`;
const KKW_BEAUTY = `${STATIC_FILE_URL}/5d1a8efc48cf07d53d45222d7958b02e.png`;
const LANVIN = `${STATIC_FILE_URL}/cce92d3fdba450d1b89aa9cdbab74146.png`;
const LEXUS = `${STATIC_FILE_URL}/5662726d113fafb9d1ddb6a43f3c428a.png`;
const LOS_CALIENTES = `${STATIC_FILE_URL}/c440ec1dd6d293ad23b24a49659cf285.png`;
const LOUIS_VUITTON = `${STATIC_FILE_URL}/813c8d7ef1b9a373f67d10ed44b185cb.png`;
const MAISON_BERGER = `${STATIC_FILE_URL}/31cf703ff0825c6185f429e9c951a91c.png`;
const MARVEL = `${STATIC_FILE_URL}/17cf38b70f3845d15e35f90519acadff.png`;
const MELISSA_WOODHEALTH = `${STATIC_FILE_URL}/56a611b87e014d054624af7d8d80dcef.png`;
const MERCEDES_BENZ = `${STATIC_FILE_URL}/020b9d671d051ceb796ad0356ef14de6.png`;
const MIND_YOUR_BUSINESS = `${STATIC_FILE_URL}/3037a93470b9a398b9bb8eafed0682eb.png`;
const MONOPOLY = `${STATIC_FILE_URL}/2a46238e1b5309294e5140018e6d03e4.png`;
const MONTAGE = `${STATIC_FILE_URL}/435e22d814c245973b82d98825964fac.png`;
const MONTBLANC = `${STATIC_FILE_URL}/f156fe59de2fffe1c92d9a41974c92f6.png`;
const MR_BEAST = `${STATIC_FILE_URL}/a42ea1a24fecabfdbf5659486af8fd14.png`;
const MY_LOVELY_FELINE = `${STATIC_FILE_URL}/1628aec2e9b06ee945abe92ce9413b32.png`;
const NEST = `${STATIC_FILE_URL}/3171ab58c20ae1281f7b724d3e8e4341.png`;
const NEXT = `${STATIC_FILE_URL}/2dea06458c9855158af13c7513be74bf.png`;
const NIKE = `${STATIC_FILE_URL}/acf47a9f66feb9f38a66c9c53bf4742b.png`;
const NIKON = `${STATIC_FILE_URL}/c581f9f1fc9a2826115b9e9ff5f78421.png`;
const OPENING_CEREMONY = `${STATIC_FILE_URL}/ad542a68f28627404b9579e7ef40ad08.png`;
const PANGAIA = `${STATIC_FILE_URL}/76c35313c9703e934a0a7ac55edeffab.png`;
const QVC = `${STATIC_FILE_URL}/e277bc31c1871339d5cb5d95647c2333.png`;
const RALPH_LAUREN = `${STATIC_FILE_URL}/a4c099fe88d94830b4d0cd6e4cfcf7de.png`;
const RESPIN = `${STATIC_FILE_URL}/2e45ffe5dbe384a474f3218f2bc28dc4.png`;
const RITUAL = `${STATIC_FILE_URL}/04094f5b357ad9a27d0a51da9c01bbbe.png`;
const SEPHORA = `${STATIC_FILE_URL}/411a8ff2cd34f312beaca5fdb6291527.png`;
const SHOPIFY = `${STATIC_FILE_URL}/0c7d471464be51d33b745cc76637b620.png`;
const SMOKE_ROSES = `${STATIC_FILE_URL}/95b3befea1e57c95baf0abb026ed2fe9.png`;
const SONY = `${STATIC_FILE_URL}/0d704c42f5f3cc74258164383038c587.png`;
const SPEEDO = `${STATIC_FILE_URL}/32785f0e2e198a49a4031493a26a51f9.png`;
const SPONGEBOB = `${STATIC_FILE_URL}/7ad763ea7e6cf96bef8222629cd418cd.png`;
const STARBUCKS = `${STATIC_FILE_URL}/fd609e3f566c3f70597e13ed2f0aa4bf.png`;
const SWAROVSKI = `${STATIC_FILE_URL}/f0abdb6d854fa30201372ef711136d69.png`;
const SXSW = `${STATIC_FILE_URL}/b9fb9b6092ff041afa5cbb632a85cbc5.png`;
const TARGET = `${STATIC_FILE_URL}/e883f31b3f3de55345fbc9b82cea7e99.png`;
const TUC_TUC = `${STATIC_FILE_URL}/6f9b9070538f8543b960e665c56f1a3b.png`;
const UGG = `${STATIC_FILE_URL}/5c4cff49a9ec6ab7a26250fc10482422.png`;
const ULTA = `${STATIC_FILE_URL}/d49a8e627b46b3f0a5637fcdf85e2b06.png`;
const UNICEF = `${STATIC_FILE_URL}/c54bb9e71f7d54613a09adf93fe87d8b.png`;
const UNIVERSAL = `${STATIC_FILE_URL}/af06465c1daebe3cf1507fe4a8b22441.png`;
const WALGREENS = `${STATIC_FILE_URL}/bc96039f1313736baaa2d3ef43462bfe.png`;
const WALMART = `${STATIC_FILE_URL}/470b607fe916823f0561602dbdc97fb0.png`;
const WE_WORE_WHAT = `${STATIC_FILE_URL}/4384d3bc0868f40da8d921a26b030c97.png`;
const QUAY = `${STATIC_FILE_URL}/735931080d37687653050d4be85bd950.png`;
const MASSIMO_DUTTI = `${STATIC_FILE_URL}/51ebdcd509e48be1f0ed204f911c658c.png`;
const DIOR = `${STATIC_FILE_URL}/53cdaf0cda7b672596673b0d978b88af.png`;

export const BRAND_LOGOS = {
  [SubCategoryName.ACTIVEWEAR]: [ADIDAS, OPENING_CEREMONY, RALPH_LAUREN, PANGAIA],
  [SubCategoryName.APPAREL]: [OPENING_CEREMONY, ADIDAS, RALPH_LAUREN, PANGAIA],
  [SubCategoryName.BABY]: [COTTON_ON, TUC_TUC, NEXT],
  [SubCategoryName.BEAUTY]: [SEPHORA, WALGREENS, ULTA, BOOTS],
  [SubCategoryName.CANDLES]: [WE_WORE_WHAT, KKW_BEAUTY, NEST, MONTAGE],
  [SubCategoryName.COFFEE_TEA]: [CHAMBERLAIN_COFFEE, STARBUCKS],
  [SubCategoryName.CONDIMENTS]: [HOME_GOODS, UNICEF, HANNAFORD, LOS_CALIENTES],
  [SubCategoryName.CLOTHING]: [OPENING_CEREMONY, ADIDAS, RALPH_LAUREN, PANGAIA],
  [SubCategoryName.FASHION_ACCESSORIES]: [DIFF, FOREVER_21],
  [SubCategoryName.FIGURINES]: [UNIVERSAL, DISNEY, AMAZON, TARGET],
  [SubCategoryName.FITNESS]: [WALMART, MELISSA_WOODHEALTH],
  [SubCategoryName.FRAGRANCES]: [BVLGARI, GUESS],
  [SubCategoryName.GAMES]: [DISNEY, MONOPOLY, CADILLAC, LEXUS],
  [SubCategoryName.HOME]: [TARGET, WALMART, BELLAGIO],
  [SubCategoryName.JEWELRY]: [LANVIN, SWAROVSKI, MONTBLANC, BADGLEY_MISCHKA],
  [SubCategoryName.LEATHER]: [MERCEDES_BENZ, KAPPA],
  [SubCategoryName.MERCH]: [SXSW, NIKE, ADIDAS, FACEBOOK],
  [SubCategoryName.PAPER]: [BUMBLE, SHOPIFY, RESPIN, MIND_YOUR_BUSINESS],
  [SubCategoryName.PETS]: [MY_LOVELY_FELINE],
  [SubCategoryName.SHOES]: [UGG, FILA, FERRAGAMO],
  [SubCategoryName.SMOKING]: [SMOKE_ROSES],
  [SubCategoryName.SWEETS]: [MR_BEAST, GUCCI, LOUIS_VUITTON],
  [SubCategoryName.SWIMWEAR]: [SPEEDO, GUCCI, ELLA_MOSS, FREE_PEOPLE],
  [SubCategoryName.SUNGLASSES]: [QUAY, DIFF, MASSIMO_DUTTI, DIOR],
  [SubCategoryName.TECH]: [HUAWEI, SPONGEBOB, SONY],
  [SubCategoryName.WELLNESS]: [MAISON_BERGER, BBB, QVC],
  [VerticalName.PACKAGING]: [MARVEL, NIKON, RITUAL],
  [VerticalName.SERVICES]: [CONDE_NAST, NIKE, EVERLANE],
};

export const HOMEPAGE_TABS: { label: string; value: SourcingHomeTab }[] = [
  {
    label: SourcingHomeTab.ALL,
    value: SourcingHomeTab.ALL,
  },
  {
    label: SourcingHomeTab.PRODUCTS,
    value: SourcingHomeTab.PRODUCTS,
  },
  {
    label: SourcingHomeTab.PACKAGING,
    value: SourcingHomeTab.PACKAGING,
  },
  {
    label: SourcingHomeTab.BUSINESS_AND_CREATIVE_SERVICES,
    value: SourcingHomeTab.BUSINESS_AND_CREATIVE_SERVICES,
  },
  {
    label: SourcingHomeTab.WEBSITE_DEVELOPMENT,
    value: SourcingHomeTab.WEBSITE_DEVELOPMENT,
  },
];

export const HOMEPAGE_TAB_TO_TEXT: { [key in SourcingHomeTab]: string } = {
  [SourcingHomeTab.ALL]: 'All',
  [SourcingHomeTab.PRODUCTS]: 'Custom products',
  [SourcingHomeTab.PACKAGING]: 'Custom packaging',
  [SourcingHomeTab.BUSINESS_AND_CREATIVE_SERVICES]: 'Business & creative services',
  [SourcingHomeTab.WEBSITE_DEVELOPMENT]: 'Website development',
};

export const MAX_OPTIONS_TO_SHOW = 4;

export const LOCATION_OPTIONS = [
  { label: 'All', value: null },
  { label: 'North America', value: SearchRegion.NA },
  { label: 'Asia', value: SearchRegion.AS },
  { label: 'Europe', value: SearchRegion.EU },
  { label: 'Latin America', value: SearchRegion.SA },
];

export const BROWSE_MARKETPLACE_TABS = (
  isServices: boolean,
  category: string,
): { key: ViewType; label: string; url: string }[] => [
  {
    key: ViewType.SUPPLIERS,
    label: isServices ? 'All freelancers' : 'All factories',
    url: `/sourcing/browse/${category}/suppliers`,
  },
  {
    key: ViewType.CATALOG_PRODUCTS,
    label: isServices ? 'All services' : 'Custom products',
    url: `/sourcing/browse/${category}/products`,
  },
  {
    key: ViewType.RFP,
    label: 'Submit custom project',
    url: `/sourcing/browse/${category}/rfp`,
  },
];

export const SUPPLIER_SORT_OPTIONS = [
  {
    label: 'Featured',
    value: SupplierSortKey.FEATURED,
  },
  {
    label: 'A - Z',
    value: SupplierSortKey.ALPHABETICAL,
  },
  {
    label: 'Newest',
    value: SupplierSortKey.NEWLY_ADDED,
  },
];

export const PRODUCT_SORT_OPTIONS = [
  {
    label: 'Featured',
    value: ProductSortKey.FEATURED,
  },
  {
    label: 'Newest',
    value: ProductSortKey.NEWLY_ADDED,
  },
  {
    label: 'Price: Low ➞ High',
    value: ProductSortKey.PRICE_ASCENDING,
  },
  {
    label: 'Price: High ➞ Low',
    value: ProductSortKey.PRICE_DESCENDING,
  },
];

export const PRODUCTS_PAGE_SIZE = 54;
export const SUPPLIERS_PAGE_SIZE = 15;

export const BROWSE_SOURCING_SCROLL_CONTAINER = 'browse-sourcing-scroll-container';

export const WORK_WITH_FACTORIES_DESCRIPTION =
  'In order to work with factories or create products on Pietra, please create an account.';
export const CHAT_WITH_FACTORY_DESCRIPTION = 'In order to chat with this factory on Pietra, please create an account.';
export const SUBMIT_RFP_DESCRIPTION =
  "In order to submit a custom project request to Pietra's network of suppliers, please create an account.";
export const CUSTOM_PACKAGING_DESCRIPTION =
  'To continue with this custom packaging order, please create an account first.';

export const BANNER_VALUE_PROPS: SourcingMarketplaceBannerValueProp[] = [
  {
    icon: '/images/sourcing/banner/rfp.svg',
    title: 'Work with 1300+ vetted factories',
    description: 'Submit a custom project and have multiple factories reach out within 48 hours with responses.',
    classname: 'sourcing-banner',
  },
  {
    icon: '/images/sourcing/banner/message-bubble.svg',
    title: 'Centralized communication',
    description: 'Avoid digging through emails - Pietra centralizes all of your communications & invoices.',
    classname: 'comms-banner',
  },
  {
    icon: '/images/sourcing/banner/hourglass.svg',
    title: 'Low minimums & lead times',
    description: 'Find factories around the world that meet your requirements without unnecessary costs.',
    classname: 'low-moq-banner',
  },
  {
    icon: '/images/sourcing/banner/headphones-customer-support.svg',
    title: 'Sourcing experts',
    description: 'Book 1:1 time with production specialists, fulfillment wizards, and e-commerce professionals.',
    classname: 'customer-support-banner',
  },
];

export const SOURCING_PRODUCT_NAME_FOR_ISR = [
  'custom-own-design-basketball-shoes',
  'custom-double-layer-long-sleeve-t',
  'custom-blind-box',
  'luxury-private-label-sunglasses-for-men',
  'high-quality-mens-pants-trousers-100',
  'dance-studio-relaxed-fit-mid-rise',
  'topshow-custom-logo-500gsm-600gsm-men',
  'custom-formulation-beef-tallow-cream-beef',
  'ipx7-cat-paw-vibrator',
  'retail-shop-shelf-ready-tray-packaging',
  'custom-personalized-sneakers-dropshipping-shoes',
  'luxury-custom-jewelry-for-women',
  'reusable-eye-patches-with-logo-print',
  'uccelli-shirt',
  'waterproof-pedometer-for-walking-with-clip',
  '240gsm-blank-black-streetwear-t-shirt',
  'custom-logo-printed-resistance-bands',
  '16-piece-abs-suitcase-w-beauty',
  'custom-paper-shopping-bag-3dza',
  'floral-print-girls-micro-bikini-set',
  'stainless-steel-automatic-watch',
];
